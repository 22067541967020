@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/fonts/icomoon.eot?anstt1');
  src:  url('../fonts/fonts/icomoon.eot?anstt1#iefix') format('embedded-opentype'),
    url('../fonts/fonts/icomoon.woff2?anstt1') format('woff2'),
    url('../fonts/fonts/icomoon.ttf?anstt1') format('truetype'),
    url('../fonts/fonts/icomoon.woff?anstt1') format('woff'),
    url('../fonts/fonts/icomoon.svg?anstt1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\e900";
  color: #449ad7;
}
.icon-arrow-btn:before {
  content: "\e901";
  color: #fff;
}
.icon-arrow-drop:before {
  content: "\e902";
  color: #d6d6d6;
}
.icon-calendar:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e904";
  color: #fff;
}
.icon-plus:before {
  content: "\e905";
  color: #fff;
}
