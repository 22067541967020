
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

@mixin headerColor($background: #fff, $btn: #f7f7f7, $btn-color: #353535, $btn-before: #449ad7) {
  header{
    background: $background;
    .btn-back{
      background: $btn;
      color: $btn-color;
      &:before{
        color: $btn-before;
      }
      &:focus,
      &:hover{
        background: darken($btn, 5);
      }
    }
  }
}

@mixin footerColor($background: #364a59, $text: #fff, $link: #fff) {
  footer{
    background: $background;
    color: $text;
    a{
      color: $link;
    }
    .footer-link{
      color: $link;
      border-color: $link;
      &:focus,
      &:hover{
        &:before {
            color: $link;
        }
      }
    }
  }
}

@mixin mainColor($body: #f2f4fa, $background: #fff, $h1: #489cd8, $h2: #767983) {
  main{
    background: $body;
    .form-body{
      background: $background;
      h1{
        color: $h1;
      }
      h2{
        color: $h2;
      }
    }
  }
}

.service-philco {
  @include headerColor;
  @include mainColor;
  @include footerColor;
}
