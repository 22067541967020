
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


label{
  display: block;
  margin:1rem 0 0.5rem 0;
  font-size: 12px;
  @include pux-scale-from("font-size", xl, 12px);
  @include pux-static-size("margin-top", 10px );
  font-weight: bold;
  color:#767983;
  text-transform: uppercase;
}

.form-control,
textarea.form-control,
input[type="text"],
input[type="password"],
.number-mask {
  width:100%;
  @include pux-scale-with-min("height", $input-height, 43px);
  padding: 0 1.5rem;
  box-shadow: 0 0 2px rgba(68, 68, 68, 0.3);
  border: 1px solid $border-color;
  background: $input-background;
  color: #a7a8ac;
  @include border-radius(4px);
  @include pux-static-size("font-size", 14px);
  resize: none;
  font-weight: 600;
  @include placeholder{
    color: $color-placeholder;
    font-weight: 400;
  }
  &:focus{
    outline: 0;
    border: 1px solid $focus-border-color;
  }
}

textarea.form-control{
  @include pux-scale-with-min("height", 240px, 190px);
  padding:2rem 1.5rem;
    &:focus{
      border: 1px solid $focus-border-color;
    }
}


input[type="submit"]{
  cursor: pointer;
  &:focus,
  &:hover{

  }
}

.required{
    label{
        &:before{
            content:"*";
            display: inline-block;
            vertical-align: 0.6rem;
            margin: 0 2px 0 0;
            @include pux-static-size("font-size", 10px);
        }
    }
}

label{
  &.required{
    &:after{
        content:"*";
        display: inline-block;
        vertical-align: 0.6rem;
        margin: 0 2px 0 0;
        color:$main-color;
        @include pux-static-size("font-size", 10px);
    }
  }
}

.file{
  padding:0 0 1rem 0;
  .uploader-upload{
      display:none;
  }
  label{
      @include flex-block;
      @include align-items(center);
      color: $upload-color;
      cursor:pointer;
      margin:0;
      outline:0 !important;
      width:100%;
      @include pux-scale-with-min("height", $input-height, 43px);
      padding: 0 1.5rem;
      border: 2px solid $border-color;
      background: $input-background;
      color:#a7a8ac;
      @include border-radius(4px);
      font-weight: 400;
      text-transform: none;
      position: relative;
      @include pux-scale-with-min("font-size", 18px, 12px);
      &:after {
          content: "\e905";
          display: inline-flex;
          @include align-items(center);
          @include justify-content(center);
          width: 25px;
          height: 25px;
          box-shadow: 0 0 3px rgba(68, 68, 68, 0.3);
          background-color: $main-color;
          border-radius: 50%;
          @include pux-scale-from("width", xl, 27px);
          @include pux-scale-from("height", xl, 27px);
          @include pux-static-size("font-size", 12px);
          color: $base-white;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          @include pux-scale-from("right", xl, 12px);
          @include transition;
      }
      &:hover,
      &:focus {
          &:after {
              background: darken($main-color, 5);
          }
      }
  }
  .file-name{
      padding:0.5rem;
      background: $file-name-background;
      color: $file-name-color;
  }
}

@if($use-blink-error == 1){
    .Error{
        select,
        .selectric,
        input[type="text"],
        .number-mask,
        textarea{
            border: 1px solid $error-input-border !important;
            @include box-shadow(inset 0 0 0 1px $error-input-border);
            @include animation(normal, blink, 1s, ease, 2);
        }
    }

    @include keyframes(blink){
        0% {
            background: $error-blink-start;
        }
        50% {
            background: $error-blink-final;
        }
        100% {
            background: $error-blink-start;
        }
    }
}

.EditingFormErrorLabel{
    @include pux-static-size("font-size", 14px);
    color:$error-label-color;
    display:block;
    margin:0.5rem 0 0 0;
}

.ErrorLabel{
    color:$error-label-color;
    display:block;
    margin:1rem 0;
}

.radio{
    input[type="radio"]{
        display:none;
        & +label{
            position:relative;
            padding:0 0 10px 25px;
            display: inline-block;
            margin:0;
            &:before{
                content:"";
                @include border-radius(50%);
                width:16px;
                height:16px;
                background:$radio-background;
                display:block;
                position:absolute;
                left:0;
                top:3px;
                border:1px solid $radio-border;
                @include transition;
            }
            &:after{
                content:"";
                display:block;
                left:5px;
                top:8px;
                width:6px;
                height:6px;
                background:$radio-after-background;
                @include border-radius(50%);
                position:absolute;
                @include opacity(0);
                @include transition;
            }
        }
        &:checked{
            & +label{
                &:before{
                    border:1px solid $radio-active-border;
                }
                &:after{
                    @include opacity(1);
                }
            }
        }
    }
}

.checkbox{
    input[type="checkbox"]{
        display:none;
        & +label{
            position:relative;
            padding:0 0 10px 25px;
            margin:0;
            display: inline-block;
            &:before{
                content:"";
                width:16px;
                height:16px;
                background:$checkbox-background;
                display:block;
                position:absolute;
                left:0;
                top:3px;
                border:1px solid $checkbox-border;
                @include transition;
            }
            &:after{
                content:"";
                display:block;
                left:5px;
                top:8px;
                width:6px;
                height:6px;
                background:$checkbox-after-background;
                position:absolute;
                @include opacity(0);
                @include transition;
            }
        }
        &:checked{
            & +label{
                &:before{
                    border:1px solid $checkbox-active-border;
                }
                &:after{
                    @include opacity(1);
                }
            }
        }
    }
}

.row-form{
  @include flex-block;
  @include flex-wrap(wrap);
  margin:0 -1.2rem;
  [class^="col"]{
    padding-left:1.2rem;
    padding-right:1.2rem;
  }
}

.form-bottom{
  max-width: 620px;
  margin:0 auto;
  margin-top: 20px;
  @include media(sm) {
      margin-top: 41px;
  }
  @include media(lg) {
      margin-top: 0;
  }
  h2 {
      margin-bottom: 1.7rem;
  }
}

.form-layout{
  @include flex-block;
  @include flex-flow(row, wrap);
  padding:6.5rem 0 4rem 0;
  &-left{
      width: 100%;
      @include media(lg) {
          max-width: calc(50% - (8rem / 2));
          flex: 0 1 calc(50% - (8rem / 2));
      }
      @include media(xxl) {
          max-width: calc(50% - (14rem / 2));
          flex: 0 1 calc(50% - (14rem / 2));
      }
  }
  &-right{
      width: 100%;
      margin-top: 40px;
      @include media(sm) {
          margin-top: 65px;
      }
      @include media(lg) {
          margin-top: 0;
          max-width: calc(50% - (8rem / 2));
          flex: 0 1 calc(50% - (8rem / 2));
      }
      @include media(xxl) {
          max-width: calc(50% - (14rem / 2));
          flex: 0 1 calc(50% - (14rem / 2));
      }
  }
}

.form-message {
    text-align: center;
    margin-bottom: 1.8rem;
    margin-top: 1rem;
    font-size: 15px;
    @include pux-scale-from("font-size",xl, 15px);
}

.form-layout-line{ 
  display: none;
  @include justify-content(center);
  @include media(lg) {
      @include flex-block;
      width: 8rem;
  }
  @include media(xxl) {
      width: 14rem;
  }
  &-inner{
    width:1px;
    background: #d6d6d6;
  }
}

.input-mask {
    input {
        letter-spacing: 8px;
    }
}
