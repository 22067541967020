
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.pux-container{
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    max-width: $container-max-width;
    &:after{
        display: block;
        content: "";
        clear: both;
    }
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
}

.pux-container{
    .row{
        @include flex-block;
        @include flex-wrap(wrap);
        margin-left: $row-margin-left;
        margin-right: $row-margin-right;
        @include media(md){
          margin-left: $row-margin-left-md;
          margin-right: $row-margin-right-md;
        }
    }
}

.pux-container{
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto{
        padding-left: $col-padding-left;
        padding-right: $col-padding-right;
        @include media(md){
          padding-left: $col-padding-left-md;
          padding-right: $col-padding-right-md;
        }
    }
}

.pux-fluid-container{
    padding-left: $fluid-container-padding-left;
    padding-right: $fluid-container-padding-right;
    @include media(md){
      padding-left: $fluid-container-padding-left-md;
      padding-right: $fluid-container-padding-right-md;
    }
    @include media(xl){
      padding-left: $fluid-container-padding-left-xl;
      padding-right: $fluid-container-padding-right-xl;
    }
}

header{
   padding:1.6rem 0;
   box-shadow: 0 0 16px rgba(68, 68, 68, 0.04);
   @include media(md) {
       box-shadow: 0 0 24px rgba(68, 68, 68, 0.04);
   }
   @include media(xl) {
       box-shadow: 0 0 29px rgba(68, 68, 68, 0.04);
   }
  .header{
    @include flex-block;
    @include align-items(center);
    @include justify-content(space-between);
    &-logo{
      a{
        display:inline-block;
        span{
          display:none;
        }
        img{
          height: 5rem !important;
        }
      }
    }
    &-menu{
      display:none;
      position: absolute;
      top:5rem;
      z-index: 77;
      left:0;
      background: $second-color;
      width: 100%;
      @include calc("max-height", "100vh - 5rem");
      @include media($menu-break){
        display:block;
        position: static;
        background: none;
        width:auto;
      }
      ul{
        margin:0;
        padding:0;
        list-style: none;
        @include media($menu-break){
          @include flex-block;
          @include align-items(center);
        }
        li{
          display: block;
          position: relative;
          @include media($menu-break){
            position: static;
            margin:0 1rem;
          }
          a{
            display:block;
            padding:1.5rem 1.5rem;
            color:$base-black;
            &:focus,
            &:hover{
              color:$main-color;
              text-decoration: none;
            }
          }
          &.Highlighted{
            a{
              color:$main-color;
            }
          }
          ul{
            background: $second-color;
            display: none;
            width:100%;
            @include opacity(1);
            visibility: visible;
            @include transition;
            @include media($menu-break){
              position: absolute;
              width:20rem;
              visibility: hidden;
              @include opacity(0);
              display: block;
            }
            li{
              a{
                &:focus,
                &:hover{

                }
              }
            }
          }
          &:hover{
            & > ul {
              visibility: visible;
              @include opacity(1);
            }
          }
          &.open{
            .menu-openner{
              &:after{
                @include rotate(0deg);
              }
            }
            ul{
              display:block;
            }
          }
        }
      }
      .menu-openner{
        position: absolute;
        top:0;
        right:0;
        background: $main-color;
        color:#fff;
        width: 6rem;
        height: 6rem;
        @include media($menu-break){
          display: none;
        }
        &:after,
        &:before{
          content:"";
          width:70%;
          height:2px;
          background:#fff;
          position: absolute;
          left:15%;
          top:50%;
          margin-top:-1px;
        }
        &:after{
          @include rotate(90deg);
          @include transition;
        }
      }
    }
    &-hamburger{
      @include media(md){
        display: none;
      }
      .open-menu{
        width:$hamburger-width;
        height:$hamburger-width;
        position: relative;
        display: block;
        span{
          width:$hamburger-width - 1rem;
          position: absolute;
          left:0.5rem;
          height:2px;
          background: $main-color;
          margin-top:-1px;
          top:50%;
          @include transition;
          &:before,
          &:after{
            content:"";
            width:$hamburger-width - 1rem;
            left:0;
            height:2px;
            background: $main-color;
            position: absolute;
            @include transition;
          }
          &:before{
            top:-1rem;
          }
          &:after{
            bottom:-1rem;
          }
        }
      }
    }
    &.open{
      .header-hamburger{
        .open-menu{
          span{
            background: transparent;
            &:before{
              top:0;
              @include rotate(45deg);
            }
            &:after{
              top:0;
              @include rotate(-45deg);
            }
          }
        }
      }
      .header-menu{
        display: block;
      }
    }
  }
  .btn-back{
    @include flex-inline;
    @include align-items(center);
    @include justify-content(center);
    @include pux-scale-with-min("font-size", 16px, 10px);
    @include border-radius(3rem);
    height: 5.9rem;
    padding:1rem 2rem;
    @include transition;
    &:before{
      content:"\e900";
      font-size:1.6rem;
      margin:0 1rem 0 0;
    }
  }
}

.EditMode,
.DesignMode {
  header {
    position: static;
  }
}

main{
  padding:4rem 10px;
  @include media(xs){
    padding:5rem 5rem;
  }
  .form-body{
    @include border-radius(5px);
    padding: 6rem 15px;
    width: 100%;
    box-shadow: 0 0 16px rgba(68, 68, 68, 0.04);
    @include media(md) {
        box-shadow: 0 0 24px rgba(68, 68, 68, 0.04);
        padding:6rem 9.5rem;
    }
    @include media(lg) {
        padding: 6rem 3rem;
    }
    @include media(xl) {
        box-shadow: 0 0 29px rgba(68, 68, 68, 0.04);
    }
    @include media(xxl) {
        padding: 6rem 9.5rem;
    }
    .ErrorLabel{
        margin-top: 3rem;
    }
    .form-help-title {
        display: inline-flex;
        @include align-items(center);
        @include justify-content(center);
        width: 18px;
        height: 18px;
        background-color: $color-red;
        border-radius: 50%;
        font-size: 11px;
        color: $base-white;
        margin-left: 5px;
        @include pux-scale-from("font-size", xl, 11px);
        @include pux-scale-from("width", xl, 18px);
        @include pux-scale-from("height", xl, 18px);
    }
    .submit-btn {
        display: inline-flex;
        @include align-items(center);
        background-color: $color-link;
        height: 61px;
        border-radius: 30px;
        @include pux-scale-from("height", xl, 61px);
        @include pux-scale-from("border-radius", xl, 30px);
        padding: 1rem 2rem;
        cursor: pointer;
        @include transition;
        &:after {
            content: "\e901";
            display: inline-flex;
            @include align-items(center);
            @include justify-content(center);
            background-color: rgba(#fff, 0.3);
            width: 33px;
            height: 33px;
            border-radius: 50%;
            @include pux-scale-from("width", xl, 33px);
            color: $base-white;
            padding-left: 0.2rem;
        }
        .FormButton {
            text-transform: uppercase;
            color: $base-white;
            font-size: 15px;
            @include pux-scale-from("font-size", xl, 19px);
            font-weight: 700;
        }
        &:hover,
        &:focus {
            background: darken($btn-primary-background, 5);
            color: $btn-primary-color;
            .FormButton {
                text-decoration: none;
                background: darken($btn-primary-background, 5);
                color: $btn-primary-color;
            }
        }
    }
  }
}

footer{
  padding:4rem 0 2rem 0;
  @include pux-scale-with-min("font-size", 16px, 12px);
  .footer{
    @include flex-block;
    @include align-items(center);
    @include justify-content(space-between);
    margin:0 -2rem;
    max-width: 100%;
    &-left{
      padding:0 2rem 2rem 2rem;
    }
    &-right{
      padding:0 2rem 2rem 2rem;
      @include flex-block;
      @include align-items(center);
      margin:0 -1rem;
      &-item{
        padding:0 1rem;
      }
    }
  }
  .footer-link{
    @include flex-inline;
    @include align-items(center);
    @include justify-content(center);
    font-size:0;
    width:5rem;
    height:5rem;
    @include transition;
    &:before{
      content:"";
      font-size:5rem;
    }
    &.facebook{
      &:before{
        content:"\e904";
      }
    }
  }
}

.is-xxl{
    display:none;
    @include media(xxl){
        display:block;
    }
}


.tooltip {
    opacity: 1 !important;
    &-inner {
        box-shadow: 0 0 3px rgba(68, 68, 68, 0.3), inset 0 0 1px 1px rgba(235, 87, 87, 0.32);
        opacity: 1;
        background-color: $base-white;
        border-radius: 5px;
        padding: 1.5rem 2rem;
        font-size: 11px;
        line-height: 1.45;
        color: $color-gray;
        text-align: left;
        min-height: 50px;
        @include pux-scale-from("border-radius", xl, 5px);
        @include pux-scale-from("font-size", xl, 11px);
    }
    &.bs-tooltip-right {
        filter: drop-shadow(0 0 3px rgba(68, 68, 68, 0.3));
        padding-left: 27px;
        .arrow  {
            left: 0px;
            &:before {
                @include triangle-left(16px, 16px, 20px, #f9c9c9);
                position: absolute;
                top: 50% !important;
                left: 7px;
                transform: translateY(-50%);
                margin: 0;
            }
            &:after {
                content: "";
                display: block;
                @include triangle-left(16px, 16px, 20px, $base-white);
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
                filter: drop-shadow(-3px 0 3px rgba(#fff, 1));
            }
        }
    }
}
.date-picker {
    position: relative;
    display: inline-block;
    position: relative;
    z-index: 22;
    width: 100%;
    @include media(md) {
        width: 60% !important;
    }
    input {
        padding-right: 50px !important;
        @include media(lg) {
            padding-right: 65px !important;
        }
    }
    &:after {
        content: "\e903";
        display: block;
        position: absolute;
        top: 50%;
        right: 0.4rem;
        transform: translate(-50%, -50%);
        @include pux-scale-with-min("font-size", 30px, 20px);
        color: #767983;
        user-select: none;
    }
}

.datepicker {
    padding: 1rem 2rem 2rem !important;
    transform: translateY(-4px);
    padding-top: 4px;
    &:before,
    &:after {
        display: none !important;
    }
    .table-condensed {
        width: 100%;
        .datepicker-switch {
            font-size: 15px;
            font-weight: 400 !important;
            @include pux-scale-from("font-size", xl, 15px);
        }
        .prev,
        .next {
            font-size: 0;
            position: relative;
            &:before {
                content: "\e901";
                position: absolute;
                font-size: 10px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @include pux-scale-from("font-size", xl, 10px);
            }
        }
        .prev {
            transform: rotate(180deg);
        }
        .today {
            background: transparent !important;
            color: $main-color !important;
            font-weight: 700 !important;
            letter-spacing: 0.3px;
        }
        .dow {
            color: #a1a1a1;
            font-family: "Open Sans";
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.24px;
            padding: 0.7rem 0;
        }
        .day {
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0.3px;
            @include pux-static-size("padding", 5px);
            @include pux-scale-from("font-size", xl, 15px);
            &.old {
                visibility: hidden;
            }
            &.new {
                display: none;
            }
        }
    }
}

.number-mask {
    display: flex;
    @include align-items(center);
    user-select: all;
    &:focus-within {
        .number-mask-empty-char,
        .number-mask-filled {
            color: #495057 !important;
        }
    }
}
.number-mask-empty-char {
    display: block;
    align-items: center;
    outline: none;
    min-width: 12px;
    height: 20px;
    margin-right: 7px;
    position: relative;
}
.number-mask-filled {
    outline: 0;
    min-width: 1px;
    color: #a7a8ac;
    @include pux-static-size("font-size", 14px);
}
.number-mask-empty-char:before {
    content: "";
    display: inline-block;
    height: 1px;
    width: 12px;
    background-color: gray;
    touch-action: none;
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.number-mask-space {
    width: 13px;
}

#ui-id-1 {
    border: 1px solid $color-line;
    @include pux-scale-from("border-width", xl, 1px);
    z-index: 99;
    background-color: $base-white;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 435px;
    max-width: 80vw;
    @include pux-scale-from("width", xl, 435px);
    max-height: 50vw;
    overflow: auto;
    li {
        font-weight: 650;
        color: #a7a8ac;
        cursor: pointer;
        margin: 0;
        padding: 5px 0px 5px 10px;
        font-size: 14px;
        @include pux-scale-from("font-size", xl, 14px);
        &:hover,
        &:focus {
            color: $main-color;
        }
    }
}

.ui-helper-hidden-accessible {
    display: none !important;
}
